import Vue from 'vue'
import VueRouter from 'vue-router'
import RoomView from '@/views/room/Index'
import Oops from '@/views/Oops'
import ServiceOver from '@/views/ServiceOver';
import TimeOver from '@/views/TimeOver'
import HelpPage from "@/views/HelpPage";

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      component: RoomView,
      name: 'index',
      props: true
    },
    {
      path: '/service-over/',
      name: 'service-over',
      component: ServiceOver
    },
    {
      path: '/time-over/',
      name: 'time-over',
      component: TimeOver
    },
    {
      path: '/help-page/',
      name: 'help-page',
      component: HelpPage
    },
    {
      path: '*',
      component: Oops,
      name: 'oops'
    }
  ]
})
